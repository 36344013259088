const is = (v: any, o: any) => Object.prototype.toString.call(v) === `[object ${o}]`;

export const objectTypes: any = {
  Array: 'Array',
  RegExp: 'RegExp',
  Object: 'Object',
};

const baseType = {
  String(v: any) {
    return typeof v === 'string' || is(v, 'String');
  },
  Number(v: any) {
    return typeof v === 'number' || is(v, 'Number');
  },
  Boolean(v: any) {
    return typeof v === 'boolean' || is(v, 'Boolean');
  },
};

//检查变量类型，可检查类型从baseType和objectTypes而来
export const typeIs = (() => {
  let typeIsObj: any = {
    ...baseType,
    Function(v: any) {
      return typeof v === 'function';
    },
  };

  for (const key in objectTypes) {
    typeIsObj[key] = function (v: any) {
      return typeof v === 'object' && is(v, objectTypes[key]);
    };
  }

  return typeIsObj;
})();

export const isNull = (v: any) => v === undefined || v === null || v === '';

export const notNull = (v: any) => v !== undefined && v !== null && v !== '';

export const everyNull = (arr: Array<any>) => arr.every(isNull);

export const everyNotNull = (arr: Array<any>) => arr.every(notNull);

export const someNull = (arr: Array<any>) => arr.some(isNull);

export const someNotNull = (arr: Array<any>) => arr.some(notNull);

export const renByOneNotNull = (v: any, o = '', defVal = '') => (notNull(v) ? (notNull(o) ? o : v) : defVal);

export const renByAllNotNull = (arr: Array<any>, o = '', defVal = '') => (everyNotNull(arr) ? o : defVal);

export const isNullArray = (arr: Array<any>) => isNull(arr) || (notNull(arr) && arr.length === 0);

export const notNullArray = (arr: Array<any>) => notNull(arr) && typeIs.Array(arr) && arr.length > 0;

export const isEmptyText = (str: string) => isNull(str) || (notNull(str) && isNull(str.trim()));

export const notEmptyText = (str: string) => notNull(str) && notNull(str.trim());

export function valueOrDefault(v: any, defaultVal: any = '') {
  return notNull(v) ? v : defaultVal;
}

export function numberOrDefault(v: any, defaultVal: any = '') {
  return notNull(v) ? Number(v) : defaultVal;
}

/**
 * 去掉一个对象上的值为空的key
 * @param {*} from 来源对象
 * @returns 新对象
 */
export const cleanObj = (from: any) => {
  if (isNull(from)) return;
  let to: any = {};
  for (const key in from) {
    if (notNull(from[key])) to[key] = from[key];
  }
  return to;
};

/**
 * 判断获取对象属性的路径是否安全
 * @param {Object} object 对象
 * @param {String} funcPath 属性字符串
 * @returns true or false
 */
export const isSafePropPath = (object: any, funcPath: string) => {
  let arr = funcPath.split('.'),
    next = true;
  let callObject = object;
  for (let i = 0; i < arr.length - 1; i++) {
    next = notNull(object[arr[i]]);
    if (next === false) return false;
    else callObject = callObject[arr[i]];
  }
  if (next) return notNull(callObject[arr[arr.length - 1]]);
  return false;
};

/**
 * 判断获取对象属性的路径是否安全
 * @param {Object} object 对象
 * @param {String} funcPath 属性字符串
 * @returns 属性值
 */
export const safePropPath = (object: any, funcPath: string) => {
  let arr = funcPath.split('.'),
    next = true;
  let callObject = object;
  for (let i = 0; i < arr.length - 1; i++) {
    next = notNull(object[arr[i]]);
    if (next === false) return false;
    else callObject = callObject[arr[i]];
  }
  if (next) return callObject[arr[arr.length - 1]];
  return null;
};
